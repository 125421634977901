import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, TableFooter, Typography } from '@mui/material';
import { Team } from '../model/Team';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  [`&.${tableCellClasses.footer}`]: {
    fontSize: 14,
    fontWeight: 'bold',
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface Props {
  team: Team;
}

const POSITION_VALUES: {[position: string]: number} = {
  'QB': 0,
  'RB': 1,
  'WR': 2,
  'TE': 3,
  'DST': 4,
  'K': 5
}

export function TeamTable(props: Props) {
  let totalScore = 0;
  props.team.players.forEach(player => {
    if (player.id === props.team.mvp.id) {
      totalScore += 1.5 * player.points;
    } else {
      totalScore += player.points;
    }
  });
  totalScore = parseFloat(totalScore.toFixed(2));
  return (
    <>
      <Box textAlign={'center'}>
        <Typography variant="overline" fontWeight={'bold'}>
          {props.team.name}
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="customized table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>Position</StyledTableCell>
              <StyledTableCell>Player</StyledTableCell>
              <StyledTableCell>Points</StyledTableCell>
              {/* <StyledTableCell>Rush yds</StyledTableCell>
            <StyledTableCell>Rush tds</StyledTableCell>
            <StyledTableCell>Rec yds</StyledTableCell>
            <StyledTableCell>Rec tds</StyledTableCell>
            <StyledTableCell>Pass yds</StyledTableCell>
            <StyledTableCell>Pass tds</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.team.players.sort((a, b) => POSITION_VALUES[a.position] - POSITION_VALUES[b.position]).map(player => (
              <StyledTableRow key={player.name}>
                <StyledTableCell>
                  {player.id === props.team.mvp.id ? player.position + ' (MVP)' : player.position}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {player.name}
                </StyledTableCell>
                <StyledTableCell>
                  {player.id === props.team.mvp.id
                    ? parseFloat((1.5 * player.points).toFixed(2))
                    : player.points.toFixed(2)}
                </StyledTableCell>
                {/* <StyledTableCell>{row.rushYds}</StyledTableCell>
              <StyledTableCell>{row.rushTds}</StyledTableCell>
              <StyledTableCell>{row.recYds}</StyledTableCell>
              <StyledTableCell>{row.recTds}</StyledTableCell>
              <StyledTableCell>{row.passYds}</StyledTableCell>
              <StyledTableCell>{row.passTds}</StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell>Total</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>{totalScore}</StyledTableCell>
              {/* <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell> */}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

export default TeamTable;
